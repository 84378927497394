<template>
  <div>
    <div class="operations">
      <div style="flex: 1"></div>
      <a-button @click="showForm = true">添加</a-button>
    </div>
    <TiyoTable :gData="dataSource" :gColumns="columns" />
    <FoodForm
      :visible="showForm"
      :formData="formState"
      @formCancel="handleCancel"
      @formOk="handleOk"
    />
  </div>
</template>
<script>
import {
  getFoods,
  updateFood,
  createFood,
  delFood
} from "../http/api";
import TiyoTable from "../components/TiyoTable.vue";
import FoodForm from "../components/form/FoodForm";
export default {
  components: {
    TiyoTable,
    FoodForm,
  },
  data() {
    return {
      showForm: false,
      formState: {},
      dataSource: [],
      columns: [
        {
          title: "id",
          dataIndex: "id",
          key: "id",
          width: 60,
        },
        {
          title: "名称",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "原始名称",
          dataIndex: "originalName",
          key: "originalName",
        },
        {
          title: "价格/100g",
          dataIndex: "price",
          key: "price",
        },
        {
          title: "分类",
          dataIndex: "category",
          key: "category",
        },
        {
          title: "每100g能量",
          dataIndex: "energyPer100g",
          key: "energyPer100g",
        },
        {
          title: "价格能量比",
          dataIndex: "priceEnergyRatio",
          key: "priceEnergyRatio",
        },
        {
          title: "营养成分",
          dataIndex: "content",
          key: "content",
          customRender: (val) => {
            return (
              <a-tooltip title={val.value}>
                <div style={{ maxWidth: "100%" }}>
                  {val.value.substring(0, 5)}
                  {val.value.length > 5 ? "..." : ""}
                </div>
              </a-tooltip>
            );
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          width: 200,
          customRender: (val) => {
            return (
              <div>
                <a-button onClick={() => this.updateFood(val.record)}>编辑</a-button>
                <a-popconfirm
                  title="是否删除该食材"
                  ok-text="是"
                  cancel-text="否"
                  onConfirm={() => this.delFood(val.record)}
                >
                  <a-button style="margin-left: 10px">删除</a-button>
                </a-popconfirm>
              </div>
            );
          },
        },
      ],
    };
  },
  created() {
    this.getFoods();
  },
  methods: {
    delFood(record) {
      delFood({variables: {objectId: record.id}}).then(res => {
        console.log(res)
        this.getFoods()
      })
    },
    handleOk(formState) {
      if (formState.objectId) {
        updateFood({ variables: formState }).then((res) => {
          console.log(res)
          this.handleCancel()
          this.getFoods()
        }).catch((err) => {
          console.log(err)
        })
      } else {
        createFood({variables: formState}).then(res => {
          console.log(res)
          this.handleCancel()
          this.getFoods()
        }).catch((err) => {
          console.log(err)
        })
      }
    },

    updateFood(record) {
      record.objectId = record.id;
      this.formState = record;
      this.showForm = true;
    },
    handleCancel() {
      this.showForm = false;
      this.formState = {};
    },
    getFoods() {
      getFoods().then((res) => {
        if (res.errors) {
          this.$message.error(res.errors[0].message);
          return;
        }
        let dataSource = res.data.foods;
        let map = {'a0': '能量(kcal)', 'b0': '总蛋白质(g)', 'c0': '精氨酸(g)', 'd0': '组氨酸(g)', 'e0': '异亮氨酸(g)', 'f0': '亮氨酸(g)', 'g0': '赖氨酸(g)', 'h0': '甲硫氨酸(g)', 'i0': '半胱氨酸(g)', 'j0': '甲硫氨酸+半胱氨酸(g)', 'k0': '苯丙氨酸(g)', 'l0': '酪氨酸(g)', 'm0': '苯丙氨酸+酪氨酸(g)', 'n0': '苏氨酸(g)', 'o0': '色氨酸(g)', 'p0': '缬氨酸', 'q0': '总脂类(g', 'r0': '亚油酸(18:2）(g)', 's0': 'a-亚麻酸(18:3）(g）', 't0': 'EPA+DHA (g)', 'u0': 'LA+AA:ALA+EPA+DHA', 'v0': '总碳水化合物(g)', 'w0': '钙(mg)', 'x0': '磷(mg)', 'y0': '钙：磷', 'z0': '钾(mg)', 'a1': '钠(mg)', 'b2': '氯(mg)', 'c2': '镁(mg)', 'd2': '铁(mg)', 'e2': '铜(mg)', 'f2': '锰(mg)', 'g2': '锌(mg)', 'h2': '碘(mg)', 'i2': '硒(mcg）', 'j2': '维生素 A(IU)', 'k2': '维生素 D(IU)', 'o2': '维生素E(mg)', 'p2': '维生素K(mg)', 'r2': '维生素B1/硫胺素(mg)', 's2': '维生素B2/核黄素(mg)', 't2': '维生素B5/泛酸(mg)', 'u2': '维生素B3/烟酸(mg)', 'v2': '维生素B6/吡哆醇(mg)', 'w2': '维生素B7/生物素(mg)', 'x2': '维生素B9/叶酸(mcg)', 'y2': '维生素B12(mcg)', 'z2': '胆碱(mg)', 'a3': '牛磺酸(g)', 'b3': '维生素E(mg)'}
        dataSource.forEach(e => {
          let content = ''
          for (let key in e) {
            if (map[key]) {
              content += `${map[key]}: ${e[key]}, ` 
            }
          }
          e.content = content
        })
        this.dataSource = dataSource;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>